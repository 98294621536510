import Handlebars from 'handlebars';

/**
* [Insights]
*
* @date        [2024]
* @revision    [0.2]
* @author      [Robby Van den Broecke]
* @type        [ES module]
*/

const cfg = {
    cache: {
        container: '[data-module="insights"]',
        cardsContainer: '[data-container="cards"]',
        card: '.card-insight',
        filter: '[data-filter]',
        filterTarget: '[data-categories]'
    },
    classes: {
        active: 'active'
    },
    data: {
        categories: 'categories',
        filter: 'filter',
        count: 'count'
    },
    events: {
        click: 'click'
    },
    options: {
        api: {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            url: "/Insight/GetInsights/",
            dataType: 'json',
        },
        parameters: {
            category: 'c',
            reset: 'reset'
        }
    },
    tpl: {
        insights: '#tpl-insights',
    }
};

export class Insights {

    constructor(options) {
        this.settings = { ...cfg, ...options };

        const { cache, tpl, data } = this.settings;
        this.container = document.querySelector(cache.container);
        this.cardsContainer = this.container?.querySelector(cache.cardsContainer);
        this.filters = this.container?.querySelectorAll(cache.filter);
        this.filterTargets = this.container?.querySelectorAll(cache.filterTarget);

        this.tpl = {
            insights: document.querySelector(tpl.insights),
        }

        this.language = document.documentElement.lang;
        this.count = this.container?.getAttribute(`data-${data.count}`);

        console.info('initialising Insights');
    }

    create() {
        if (this.container) {
            const currentFilterValue = this.getCurrentFilterValue();

            this.bindEvents();
            this.setActiveFilter(currentFilterValue);
            this.setCardInitialHeight()
        }
    }

    bindEvents() {
        const { events } = this.settings;

        this.filters.forEach(element => {
            element.addEventListener(events.click, this.handleFilterClick.bind(this));
        });
    }

    setCardInitialHeight() {
        const { cache } = this.settings;
        const cards = this.cardsContainer.querySelectorAll(cache.card);


        // Function to handle setting card height
        const setCardHeight = (item) => {
            item.style.setProperty('--oh', `${item.clientHeight}px`);
        };

        Array.from(cards).forEach((item) => {
            // Check if the image is lazy-loaded
            const lazyImage = item.querySelector('img[loading="lazy"]');
            if (lazyImage) {
                // Listen for the lazyload event on the image
                lazyImage.addEventListener('lazyload', () => {
                    setCardHeight(item);
                });
            } else {
                // If image is not lazy-loaded, set the card height immediately
                setCardHeight(item);
            }
        });
    }

    handleFilterClick(ev) {
        const { data } = this.settings;

        ev.preventDefault();

        const filterItem = ev.currentTarget;
        const currentFilterValue = filterItem.dataset[data.filter];

        this.clearActiveFilters();
        this.setActiveFilter(currentFilterValue);
        this.filterItems(currentFilterValue);
        this.updateUrl(currentFilterValue);
    }

    clearActiveFilters() {
        const { classes } = this.settings;
        Array.from(this.filters).forEach((filter) => {
            filter.classList.remove(classes.active);
        });
    }

    filterItems(filterValue) {
        const { options } = this.settings;

        const requestData = {
            id: filterValue == options.parameters.reset ? '' : filterValue,
            language: this.language,
            count: this.count
        };
        const tplHtml = this.tpl.insights.innerHTML;
        const that = this;

        let ajaxOptions = options.api;
        ajaxOptions.body = JSON.stringify(requestData);

        fetch(ajaxOptions.url, ajaxOptions)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok: ' + response.status + ' ' + response.statusText);
                }
                return response.json();
            })
            .then(data => {
                that.renderTemplate(tplHtml, data);
                that.setCardInitialHeight();
            })
            .catch(error => {
                console.warn('Request failed:', error);
            });
    }

    setActiveFilter(filterId) {
        const { classes, data, options } = this.settings;
        const selectedFilter = this.container.querySelector(`[data-${data.filter}="${filterId || options.parameters.reset}"]`);

        selectedFilter?.classList.add(classes.active);
    }

    getCurrentFilterValue() {
        const { options } = this.settings;

        const urlParams = new URLSearchParams(window.location.search);
        const cValue = urlParams.get(options.parameters.category);

        return cValue;
    }

    updateUrl(filterValue) {
        const { options } = this.settings;

        const queryParams = new URLSearchParams(window.location.search);
        if (filterValue == options.parameters.reset) {
            queryParams.delete(options.parameters.category);
        } else {
            queryParams.set(options.parameters.category, filterValue);
        }

        // Check if there are any remaining parameters
        const queryString = queryParams.toString();
        const updatedSearch = queryString ? `?${queryString}` : '';

        // Update the URL
        const newUrl = window.location.pathname + updatedSearch + window.location.hash;
        window.history.replaceState({}, '', newUrl);

    }

    renderTemplate(tplHtml, json) {
        const { cache } = this.settings;
        const tpl = Handlebars.compile(tplHtml);
        const html = tpl(json);

        document.querySelector(cache.cardsContainer).innerHTML = html;
    }
}