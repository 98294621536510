import * as bootstrap from 'bootstrap';

/**
 * [Carousel]
 * 
 * @date    [2024]
 * @author  [Tim Vermaelen]
 * @version [0.2]
 * @type    [ES Module]
 */
const cfg = {
    cache: {
        container: '[data-class="carousel"]'
    },
    options: {
        bs: {
            carousel: {
                touch: true
            }
        }
    }
};

export class Carousel {
    constructor(options) {
        this.settings = { ...cfg, ...options };

        const { cache } = this.settings;
        this.containers = document.querySelectorAll(cache.container);
        this.carousels = new Set();
        console.info('initialising Carousel');
    }

    create() {
        const { options } = this.settings;

        this.containers.forEach(carousel => {
            const instance = new bootstrap.Carousel(carousel, options.carousel);
            this.carousels.add(instance);
        })
    }
}
