import { Offcanvas } from 'bootstrap';

/**
 * [Navigation]
 * 
 * @date    [2024]
 * @author  [Tim Vermaelen]
 * @version [0.2]
 * @type    [ES Module]
 */
const cfg = {
    cache: {
        container: '[data-module="navigation"]',
        navItem: '.nav-item [href]'
    },
    events: {
        click: 'click'
    }
};

export class Navigation {
    constructor(options) {
        this.settings = { ...cfg, ...options };
        const { cache } = this.settings;

        this.container = document.querySelector(cache.container);
        this.offCanvas = Offcanvas.getOrCreateInstance(this.container);

        console.info('initialising Navigation');
    }

    create() {
        this.detect();
        this.modifyConfig();
        this.listen();
    }

    detect() {
        const { cache } = this.settings;

        const navItems = this.offCanvas._element.querySelectorAll(cache.navItem);
        this.navItems = [...navItems].filter(x => x.href.indexOf('#') !== -1);
    }

    modifyConfig() {
        this.offCanvas._config.scroll = !!this.navItems.length;
    }

    listen() {
        const { events } = this.settings;

        [...this.navItems].forEach((navItem, idx) => {
            navItem.addEventListener(events.click, this.handleNavItems.bind(this))
        })
    }

    handleNavItems(ev) {
        const isRelative = ev.currentTarget.href.indexOf('#') !== -1;

        isRelative && this.offCanvas.hide();
    }
}