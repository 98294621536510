import { Dom } from "../js/app/modules/ns.dom";
import { Inject } from "../js/app/modules/ns.inject";
import { ScrollToTop } from "../js/app/modules/ns.scroll-to-top";
import { Carousel } from "../js/app/modules/ns.carousel";
import { Insights } from "../js/app/modules/ns.insights";
import { Navigation } from "./app/modules/ns.navigation";

let dom = new Dom();
let scrollToTop = new ScrollToTop();
let inject = new Inject();
let carousel = new Carousel();
let insights = new Insights();
let navigation = new Navigation();

dom.create();
scrollToTop.create();
inject.create();
carousel.create();
insights.create();
navigation.create();
