/**
 * [Dom]
 * 
 * @date    [2024]
 * @author  [Tim Vermaelen]
 * @version [0.3]
 * @type    [ES Module]
 */
const cfg = {
    cache: {
        observer: {
            target: 'main section'
        }
    },
    classes: {
        scrolled: 'scrolled',
        inView: 'in-view',
        animation: {
            play: 'animation-play',
            pause: 'animation-pause'
        }
    },
    events: {
        scroll: 'scroll'
    },
    options: {
        eventListener: {
            passive: true
        },
        observerOptions: {
            root: null,
            rootMargin: '0px',
            threshold: 0.5
        }
    }
};

export class Dom {
    constructor(options) {
        this.settings = { ...cfg, ...options };
        this.visibleSections = new Set();
        this.invisibleSections = new Set();

        console.info('initialising Dom');
    }

    create() {
        this.setEvents();
        this.observeSections();
    }

    setEvents() {
        const { events, options } = this.settings;

        window.addEventListener(events.scroll, this.handleScroll.bind(this), options.eventListener);
    }

    handleScroll() {
        const { classes } = this.settings;
        const currentScrollY = window.scrollY;

        document.body.classList.toggle(classes.scrolled, !!currentScrollY);
    }

    observeSections() {
        const { cache, options } = this.settings;
        const observer = new IntersectionObserver(this.intersectionCallback.bind(this), options.observerOptions);
        const sections = document.querySelectorAll(cache.observer.target);

        sections.forEach(section => {
            observer.observe(section);
        });
    }

    intersectionCallback(entries) {
        entries.forEach(entry => {
            const target = entry.target;

            if (entry.isIntersecting) {
                this.visibleSections.add(target);
                this.invisibleSections.delete(target);
            } else {
                this.invisibleSections.add(target);
                this.visibleSections.delete(target);
            }
        });

        this.setVisibleState();
        this.setInvisibleState();
    }

    setVisibleState() {
        const { classes } = this.settings;

        this.visibleSections.forEach(section => {
            section.classList.add(classes.inView);
            section.classList.add(classes.animation.play);
            section.classList.remove(classes.animation.pause);
        });
    }

    setInvisibleState() {
        const { classes } = this.settings;

        this.invisibleSections.forEach(section => {
            section.classList.remove(classes.inView);
            section.classList.remove(classes.animation.play);
            section.classList.add(classes.animation.pause);
        });
    }
}
