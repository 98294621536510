/**
 * [ScrollToTop]
 *
 * @date        [2024]
 * @version     [0.3]
 * @author      [Tim Vermaelen]
 * @type        [ES module]
 */
const cfg = {
    cache: {
        container: '[data-module="scroll-to-top"]',
        track: 'main :nth-child(1)'
    },
    classes: {
        visible: 'visible',
        active: 'active'
    },
    data: {
        enabled: 'enabled'
    },
    events: {
        click: 'click'
    },
    options: {
        observer: {
            rootMargin: '-183px 0px 0px 0px',
            threshold: [0.2, 0.8]
        },
        scrollTo: {
            top: 0,
            behavior: 'smooth'
        }
    }
};

export class ScrollToTop {
    constructor(options) {
        this.settings = { ...cfg, ...options };

        const { cache } = this.settings;
        this.container = document.querySelector(cache.container);
        this.tracks = document.querySelector(cfg.cache.track);

        console.info('initialising ScrollToTop');
    }

    create() {
        const { data } = this.settings;

        if (this.container.dataset[data.enabled] === 'true') {
            this.bindEvents();
            this.observe();
        }
    }

    bindEvents() {
        const { events, options } = this.settings;

        this.intersectionObserver = new IntersectionObserver(this.monitor.bind(this), options.observer);
        this.container.addEventListener(events.click, this.scrollToTop.bind(this));
    }

    observe() {
        this.intersectionObserver.observe(this.tracks);
    }

    /**
     * Callback function for the observer
     * @@param {Array} elements observer entries
     * @@param {Object} _observer observer params
     */
    monitor(entries, _observer) {
        const { classes } = this.settings;

        entries.forEach((entry) => {
            this.container.classList.toggle(classes.visible, !entry.isIntersecting);
        });
    }

    scrollToTop(_ev) {
        const { options, classes } = this.settings;

        this.container.classList.add(classes.active);
        window.scrollTo(options.scrollTo);

        setTimeout(() => {
            this.container.classList.remove(classes.active);
        }, 800);
    }
}
