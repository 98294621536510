/**
 * [Inject]
 * 
 * @date    [2024]
 * @author  [Tim Vermaelen]
 * @version [0.1]
 * @type    [ES Module]
 */
const cfg = {
    cache: {
        container: '[data-module="inject"]'
    }
};

export class Inject {
    constructor(options) {
        this.settings = { ...cfg, ...options };

        const { cache } = this.settings;
        this.containers = document.querySelectorAll(cache.container);
        console.info('initialising Inject');
    }

    create() {
        this.containers.forEach(container => {
            const element = this.createElement(container);

            this.append(element, container);
        });
    }

    createElement(container) {
        const type = container.dataset.type;
        let element;

        switch (type) {
            case 'text/javascript':
                element = document.createElement('script');
                element.src = container.dataset.src;
                break;
            case 'text/css':
                element = document.createElement('style');
                element.href = container.dataset.href;
                break;
        }

        element.type = type;

        return element;
    }

    append(element, container) {
        const target = container.dataset.target;

        switch (target) {
            case 'self':
                container.replaceWith(element);
                break;
            case 'body':
                document.body.appendChild(element);
                break;
            default:
                document.head.appendChild(element);
                break;
        }
    }
}